/* eslint-disable react/self-closing-comp */
export default function Spinner() {
  return (
    <div
      className="mx-auto flex items-center justify-center h-5 w-5
            animate-spin rounded-full
            border-2
            border-neutral-500 
            border-t-white"
    ></div>
  );
}
