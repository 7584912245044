/* eslint-disable no-nested-ternary */
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useRouter } from 'src/routes/hooks';

import { useAuthContext } from 'src/auth/hooks';
import RabbitIcon from 'src/assets/icons/ride-along-logo.svg';

import FormProvider from 'src/components/hook-form';
import { Button } from 'src/components/button';
import { usePostLogin } from 'src/api/auth';
import { useGetCountryCode } from 'src/api/user';
import { Spinner } from 'src/components/spinner';
import { Typography } from 'src/components/typography';
import CircularProgress from 'src/components/circular-progress';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/select';
import { PATH_AFTER_LOGIN } from 'src/config-global';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import clsx from 'clsx';

// ----------------------------------------------------------------------

export default function LoginPhone({
  onNextStep,
  onSuccess,
}: {
  onNextStep: ({ mobile, code }: { mobile: string; code: string }) => void;
  onSuccess?: () => void;
}) {
  const { login } = useAuthContext();
  const [searchParams] = useSearchParams();
  const _phoneNumber = searchParams.get('phone') || '';
  const _countryCode = searchParams.get('countryCode') || '';
  const [countryCode, setCountryCode] = useState<string | undefined>(undefined);
  const router = useRouter();
  const { mutateAsync: loginMutate, isPending: loginLoading } = usePostLogin();
  const { data: countryCodesData, error: errorCountryCodes, isLoading } = useGetCountryCode();

  const LoginSchema = Yup.object().shape({
    phone: Yup.string().required('Phone is required'),
  });

  const defaultValues = {
    phone: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  useEffect(() => {
    if (_phoneNumber.length > 0) {
      loginMutate({
        country_code: `+${_countryCode}`,
        phone: `+${_countryCode}${_phoneNumber}`,
        phone_seperate: _phoneNumber,
      })
        .then((res) => {
          onSuccess?.();
          if (res && res.user) {
            login({ user: res.user, result: res.result, token: res.token });
          }
          onNextStep({ mobile: _phoneNumber, code: `+${_countryCode}` });
        })
        .catch((error: AxiosError<{ message: string }>) => {
          toast.error(error.response?.data.message);
        });
    }
  }, [_phoneNumber, _countryCode, loginMutate, onSuccess, onNextStep, login]);

  const { handleSubmit, register } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const countryData = countryCodesData?.data.filter((country) => country.name === countryCode)[0];
    if (!countryCode || !countryData) return;

    loginMutate({
      country_code: countryData.country_code,
      phone: countryData.country_code + data.phone,
      phone_seperate: data.phone,
    })
      .then((res) => {
        if (res && res.user) {
          login({ user: res.user, result: res.result, token: res.token });
          if (onSuccess) {
            onSuccess?.();
          } else {
            router.push(PATH_AFTER_LOGIN);
          }
          return;
        }
        onNextStep({ mobile: data.phone, code: countryData.country_code });
      })
      .catch((error: AxiosError<{ message: string }>) => {
        toast.error(error.response?.data.message);
      });
  });

  const renderHead = (
    <div className="flex flex-col mb-12 justify-center items-center">
      <img src={RabbitIcon} height={150} width={150} alt="logo" />
    </div>
  );
  const renderForm = (
    <div
      className="flex flex-col space-y-4"
      // spacing={2.5}
    >
      <div className="flex flex-col space-y-1">
        <Typography variant="heading3" weight="bold" className=" w-full flex text-[#464D4F]">
          Mobile Number
        </Typography>
        <Typography variant="heading5" className="  w-full flex text-[#737E80]">
          Please enter your mobile number
        </Typography>
      </div>
      {/* {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>} */}
      <div className="flex items-center h-10 justify-center space-x-2 bg-white border border-[#DDE7E9] rounded-lg text-center text-[#464D4F]">
        <Select value={countryCode} onValueChange={setCountryCode}>
          <SelectTrigger
            className={clsx(
              'text-[#01AFF6] outline-none ring-0 border-r-0 focus-within:ring-0 focus-within:outline-none ring-offset-0 focus:ring-offset-0 active:ring-offset-0 focus:ring-0 focus:outline-none focus-visible:ring-0  focus-visible:outline-0'
            )}
          >
            <SelectValue placeholder="Country Code">
              <div className="flex w-full space-x-2">
                <span className="block truncate">
                  {
                    countryCodesData?.data.filter((country) => country.name === countryCode)[0]
                      ?.country_code
                  }
                </span>
              </div>
            </SelectValue>
          </SelectTrigger>
          <SelectContent className="w-full bg-blue-50">
            {countryCodesData?.data.map((country, index) => (
              // <Listbox.Option
              //   key={index}
              //   className={({ active }) =>
              //     `relative z-50 cursor-default select-none space-y-4 p-2  ${
              //       active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
              //     }`
              //   }
              //   value={country}
              // >
              //   {({ selected }) => (

              //   )}
              // </Listbox.Option>
              <SelectItem
                className="w-full flex justify-start p-0"
                key={index}
                value={country.name}
              >
                <div className="flex w-full space-x-2 ">
                  <img src={country.icon} height={24} width={24} alt="country" />

                  <span
                  // className={` flex-1 truncate ${selected ? 'font-medium' : 'font-normal'}`}
                  >
                    {country.name}
                  </span>
                  {/* {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null} */}
                </div>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <input
          {...register('phone')}
          className="flex-1  border-none focus:border-none focus:outline-none focus-visible:border-none focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:ring-offset-no focus:ring-offset-0"
          placeholder="phone number"

          // name="phone" label="Phone Number"
        />
      </div>
      {/* <ErrorText errorMessage={error?.message} /> */}

      <Button disabled={loginLoading} className="w-full" color="primary">
        {loginLoading ? <CircularProgress /> : 'Continue'}
      </Button>
    </div>
  );

  return (
    <div className="w-5/6">
      <FormProvider methods={methods} onSubmit={onSubmit}>
        {renderHead}

        {isLoading ? (
          <Spinner />
        ) : errorCountryCodes ? (
          <Typography>Error</Typography>
        ) : (
          countryCodesData && renderForm
        )}
      </FormProvider>
    </div>
  );
}
