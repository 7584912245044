import { useState, useEffect, useCallback } from 'react';

import { useRouter } from 'src/routes/hooks';

import { usePostLogin, usePostVerifyLogin } from 'src/api/auth';

import { Button } from 'src/components/button';
import { OtpInput } from 'src/components/otpInput';
import { Typography } from 'src/components/typography';
import { useAuthContext } from 'src/auth/hooks';
import { toast } from 'react-toastify';
import { PATH_AFTER_LOGIN } from 'src/config-global';
import { AxiosError } from 'axios';
import CircularProgress from 'src/components/circular-progress';
import { ResendTimer } from 'src/components/resendTimer';
import RabbitIcon from 'src/assets/icons/rabbit-hi.svg';

const LoginOtp = ({ phone, countryCode }: { phone: string; countryCode: string }) => {
  const { login } = useAuthContext();

  const [otpCode, setOtpCode] = useState<string | undefined>('');
  const [resetTimer, setResetTimer] = useState(false);
  const { mutateAsync: loginMutate, isPending: loginLoading } = usePostLogin();

  // const {signIn } = useAuth<AuthContextType>();
  // const postLoginCodeQuery = usePostLoginCode();
  const router = useRouter();

  // const [searchParams] = useSearchParams();
  // const redirectUrl = searchParams.get('callbackUrl');
  // let formattedUrl = '/user-panel/profile';
  // if (redirectUrl) {
  //   formattedUrl = `/user-panel/${redirectUrl.substring(
  //     redirectUrl.lastIndexOf('/') + 1,
  //     redirectUrl.length
  //   )}`;
  // }
  const { mutateAsync: verifyMutate, isPending: isPendingVerify } = usePostVerifyLogin();
  const onSubmit = useCallback(
    (e?: React.SyntheticEvent) => {
      e?.preventDefault(); // mobile param is required in next page
      if (otpCode && otpCode.length === 4) {
        verifyMutate({
          country_code: countryCode,
          phone: countryCode + phone,
          phone_seperate: phone,
          verification_code: otpCode,
        })
          .then((res) => {
            if (res.user) {
              login(res);
              router.push(PATH_AFTER_LOGIN);
            } else {
              toast.error('error!');
            }
          })
          .catch((error: AxiosError<{ message: string }>) => {
            console.log({ error }, error.response);
            toast.error(error.response?.data.message);
          });
        // const response = await signIn("credentials", {
        //   mobile: username,
        //   password: password,
        //   code: otpCode,
        //   redirect: false,
        //   // callbackUrl:"/dashboard"
        // });
        // if (!response?.error) {
        //   toast.success("با موفقیت وارد شدید");
        //   setLoading(false);
        //   // router.push(formattedUrl);
        //   router.push(returnTo || PATH_AFTER_LOGIN);
        // } else {
        //   setLoading(false);

        //   setOtpCode("");
        //   toast.error(response?.error);
        // }
      }
    },
    [countryCode, login, otpCode, phone, router, verifyMutate]
  );

  useEffect(() => {
    // automatic call api when user fill 5 input
    if (otpCode?.length === 4) {
      onSubmit();
    }
  }, [onSubmit, otpCode]);

  // const {
  //   mutateAsync: loginCodeMutate,
  //   isLoading: isLoadingLoginCode,
  //   error: loginCodeServerError,
  // } = postLoginCodeQuery;

  const onClickResendCode = () => {
    // just call again sending code when timer is 0 second
    loginMutate({
      country_code: countryCode,
      phone: countryCode + phone,
      phone_seperate: phone,
    })
      .then(() => {
        toast.success('کد تایید مجددا ارسال شد');
        setResetTimer(true);
      })
      .catch((error: AxiosError<{ message: string }>) => {
        toast.error(error.response?.data.message || error.message);
      });
  };

  const renderHead = (
    <div className="flex flex-col mb-8 justify-center items-center">
      <img src={RabbitIcon} alt="rabit" height={150} width={150} />
    </div>
  );

  const renderForm = (
    <div className="flex flex-col space-y-1">
      <Typography className="text-[#464D4F] " weight="bold" variant="heading3">
        Verification Code
      </Typography>
      <Typography className="text-[#737E80]" variant="heading5">
        Enter the code sent to{' '}
        <b className="text-[#464D4F]  font-bold  px-1">{countryCode + phone}</b>
      </Typography>
      <Button onClick={() => router.reload()} className="text-[#01AFF6]" variant="ghost">
        Change phone number
      </Button>
      <form className=" space-y-4" onSubmit={onSubmit}>
        <div dir="ltr" className="relative">
          {/* <input name="csrfToken" type="hidden" defaultValue={csrfToken} /> */}

          <OtpInput
            value={otpCode}
            onChange={(data: string) => setOtpCode(data)}
            numInputs={4}
            shouldAutoFocus
            isInputNum
          />

          {/* <Button onClick={onClickResendCode} className="mx-auto flex items-center justify-center ">
            <Typography variant="paragraph1" className="text-blue-500">
              Resend Code!
            </Typography>
          </Button> */}

          <ResendTimer resetTimer={resetTimer}>
            <Button
            disabled={loginLoading}
              onClick={onClickResendCode}
              variant="ghost"
              className="mx-auto w-full text-[#01AFF6] flex items-center justify-center"
            >
             {loginLoading ? <CircularProgress/> :"Resend Code"}
            </Button>
          </ResendTimer>
        </div>

        <div className="flex flex-col">
          <Button disabled={isPendingVerify} className="w-full">
            {isPendingVerify ? <CircularProgress /> : 'Confirm'}
          </Button>
        </div>
      </form>
    </div>
  );

  return (
    <div className="flex flex-col">
      {renderHead}

      {renderForm}
    </div>
  );
};

export default LoginOtp;
