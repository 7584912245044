// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/login`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    profileView: `${ROOTS.DASHBOARD}/profile-view`,
    profileEdit: `${ROOTS.DASHBOARD}/profile-edit`,
    identificationEdit: `${ROOTS.DASHBOARD}/identification-edit`,
    identificationView: `${ROOTS.DASHBOARD}/identification-view`,
    driverLicenseEdit: `${ROOTS.DASHBOARD}/driver-license-edit`,
    driverLicenseView: `${ROOTS.DASHBOARD}/driver-license-view`,
    emailStatus: `${ROOTS.DASHBOARD}/email-status`,
    passengerPayments: `${ROOTS.DASHBOARD}/passenger-payments`,
    wallet: `${ROOTS.DASHBOARD}/wallet`,
    history: `${ROOTS.DASHBOARD}/history`,
    deleteAccount: `${ROOTS.DASHBOARD}/delete-account`,
  },
};
