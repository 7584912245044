/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';
import { Typography } from '../typography';
import { Pie } from '../circular-progress-bar';

const AUTHTIMER = 100;

export interface IResendTimerProps {
  isStart?: boolean;
  children: React.ReactNode;
  resetTimer?: boolean;
  hideTimer?: boolean;
}

export default function ResendTimer({
  hideTimer,
  resetTimer,
  children,
  isStart = true,
}: IResendTimerProps) {
  const [timer, setTimer] = useState(AUTHTIMER);

  useEffect(() => {
    if (isStart) {
      if (timer > 0) {
        const timeout = setTimeout(() => {
          setTimer((timerState) => timerState - 1);
        }, 1000);
        return () => {
          clearTimeout(timeout);
        };
      }
    }
  }, [isStart, timer]);

  useEffect(() => {
    if (resetTimer) {
      setTimer(AUTHTIMER);
    }
  }, [resetTimer]);

  return (
    <>
      {!hideTimer && timer > 0 ? (
        <div className=" mx-2 flex w-full flex-col items-center justify-center text-center">
          {/* <Typography variant="text4" color="dark">
            {Math.floor(timer / 60)}:{timer % 60}
          </Typography> */}
          <Pie second={timer} />
          <Typography className="text-[#97A3A5]" variant="paragraph3">
            Resend Code
          </Typography>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
}
