import { useQuery, useMutation, UseMutationResult } from '@tanstack/react-query';
import axiosInstance from 'src/utils/axios';

const postLogin = async (body: TLoginBody): Promise<TLoginResponse> => {
  const { data } = await axiosInstance.post(`/api/check-phone-number`, body);
  return data;
};

const verifyLogin = async (body: TLoginVerifyBody): Promise<TLoginVerifyResponse> => {
  const { data } = await axiosInstance.post(`/api/check-otp`, body);
  return data;
};

const postLogout = async (): Promise<TLogoutResponse> => {
  const { data } = await axiosInstance.post(`/auth/logout`, {});
  return data;
};
const getUserInfo = async (): Promise<TUserInfoResponse> => {
  const { data } = await axiosInstance.get(`/api/user/info?version=4`);
  return data;
};

// hooks
function useGetUserInfo({ enabled = true }: { enabled?: boolean }) {
  return useQuery({ queryKey: ['user-info'], queryFn: () => getUserInfo(), enabled });
}
const usePostLogin = (): UseMutationResult<TLoginResponse, unknown, TLoginBody> =>
  useMutation({ mutationFn: (body: TLoginBody) => postLogin(body) });

const usePostVerifyLogin = (): UseMutationResult<TLoginVerifyResponse, unknown, TLoginVerifyBody> =>
  useMutation({ mutationFn: (body: TLoginVerifyBody) => verifyLogin(body) });

const usePostLogout = (): UseMutationResult<TLogoutResponse, unknown, {}> =>
  useMutation({ mutationFn: () => postLogout() });

export { usePostLogin, usePostLogout, useGetUserInfo, usePostVerifyLogin };
