import clsx from "clsx";
import * as React from "react";
import { tv, type VariantProps } from "tailwind-variants";

const typography = tv(
  {
    base: "font-normal ",
    variants: {
      variant: {
        display1: "text-[50px] leading-[80px]",
        display2: "text-[42px] leading-[72px]",
        heading1: "text-[34px]",
        headingMobile1: "text-[24px] leading-[40px]  ",
        heading2: "text-[27px] leading-[47px]",
        headingMobile2: "text-[27px] leading-[40px] ",
        heading3: "text-[23px] leading-[40px]",
        headingMobile3: "text-[20px] leading-[34px]",
        heading4: "text-[20px] ",
        headingMobile4: "text-[16px] leading-[28px]",
        heading5: "text-[16px] leading-[30px]",
        headingMobile5: "text-[13px] leading-[23px]",
        heading6: "text-[13px] leading-[23px] ",
        headingMobile6: "text-[10px] leading-[20px] ",
        paragraph1: "text-[12px] leading-[17px]",
        paragraph2: "text-[13px] leading-[22px] ",
        paragraph3: "text-[14px] leading-[24px]",
        caption: "text-[11px] leading-[18px] ",
        footer: "text-[10px] leading-[14px] ",
      },
      weight: {
        extraBold: "font-extrabold",
        bold: "font-bold",
        semiBold: "font-semiBold",
        medium: "font-medium",
        regular: "font-normal",
      },
    },
    compoundVariants: [
      {
        variant: "heading1",
        weight: ["extraBold", "semiBold", "regular"],
        className: "leading-[56xp]  ",
      },
      {
        variant: "heading1",
        weight: "bold",
        className: " leading-[65xp]",
      },
      {
        variant: "heading4",
        weight: "regular",
        className: " leading-[50xp]",
      },
      {
        variant: "heading4",
        weight: ["extraBold", "semiBold", "bold"],
        className: " leading-[34xp] ",
      },
    ],

    defaultVariants: {
      variant: "paragraph2",
    },
  },
  {
    responsiveVariants: ["xl"],
  }
);

type TypographyVariants = VariantProps<typeof typography>;

export type ITypographyProps = {
  children: React.ReactNode;
} & TypographyVariants &
  Omit<React.ComponentPropsWithoutRef<"span">, "weight">;

export default function Typography({ variant, weight, ...props }: ITypographyProps) {
  return (
    <span {...props} className={clsx(props.className, typography({ variant, weight }))}>
      {props.children}
    </span>
  );
}
