import React from 'react';

function CircularProgress() {
  return (
    <div
      className="mx-auto flex items-center justify-center h-5 w-5
          animate-spin rounded-full
          border-2
          border-gray-500 
          border-t-white"
    />
  );
}

export default CircularProgress;
