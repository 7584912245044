/* eslint-disable no-nested-ternary */
const cleanPercentage = (percentage: number) => {
  const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0; // we can set non-numbers to 0 here
  const isTooHigh = percentage > 100;
  return isNegativeOrNaN ? 0 : isTooHigh ? 100 : +percentage;
};

const Circle = ({ colour, percentage }: { colour: any; percentage?: any }) => {
  const r = 35;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - percentage) * circ) / 100; // where stroke will start, e.g. from 15% to 100%.
  return (
    <circle
      r={r}
      cx={50}
      cy={50}
      fill="transparent"
      stroke={strokePct !== circ ? colour : ''} // remove colour as 0% sets full circumference
      strokeWidth="0.2rem"
      strokeDasharray={circ}
      strokeDashoffset={percentage ? strokePct : 0}
    />
  );
};

const Text = ({ percentage }: { percentage: number }) => (
  <text x="51%" y="51%" dominantBaseline="central" textAnchor="middle" fontSize="1em">
    {percentage.toFixed(0)}
  </text>
);

export const Pie = ({ second }: { second: number }) => {
  const pct = cleanPercentage(second);
  return (
    <svg width={100} height={100}>
      <g transform={`rotate(-90 ${'50 50'})`}>
        <Circle colour="#D9D9D9" />
        <Circle colour="#01AFF6" percentage={pct} />
      </g>
      <Text percentage={pct} />
      <span>sec</span>
    </svg>
  );
};
