import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard'));
const SearchRidePage = lazy(() => import('src/pages/search-ride'));
const ProfileViewPage = lazy(() => import('src/pages/profile/profile-view'));
const ProfileEditPage = lazy(() => import('src/pages/profile/profile-edit'));
const IdentificationViewPage = lazy(() => import('src/pages/profile/identification-view'));
const IdentificationEditPage = lazy(() => import('src/pages/profile/identification-edit'));
const DriverLicenseViewPage = lazy(() => import('src/pages/profile/driver-license-view'));
const DriverLicenseEditPage = lazy(() => import('src/pages/profile/driver-license-edit'));
const EmailStatusPage = lazy(() => import('src/pages/profile/email-status'));
const PassengerPaymentsPage = lazy(() => import('src/pages/profile/passenger-payments'));
const WalletPage = lazy(() => import('src/pages/profile/wallet'));
const HistoryPage = lazy(() => import('src/pages/profile/history'));
const DeleteAccountPage = lazy(() => import('src/pages/profile/delete-account'));
const ChatPage = lazy(() => import('src/pages/chat-detail/index'));
const ConversationPage = lazy(() => import('src/pages/conversation/index'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { element: <SearchRidePage />, path: 'search-ride' },
      { element: <ProfileViewPage />, path: 'profile-view' },
      { element: <ProfileEditPage />, path: 'profile-edit' },
      { element: <IdentificationViewPage />, path: 'identification-view' },
      { element: <IdentificationEditPage />, path: 'identification-edit' },
      { element: <DriverLicenseViewPage />, path: 'driver-license-view' },
      { element: <DriverLicenseEditPage />, path: 'driver-license-edit' },
      { element: <EmailStatusPage />, path: 'email-status' },
      { element: <PassengerPaymentsPage />, path: 'passenger-payments' },
      { element: <WalletPage />, path: 'wallet' },
      { element: <HistoryPage />, path: 'history' },
      { element: <DeleteAccountPage />, path: 'delete-account' },
      { element: <ChatPage />, path: 'chat' },
      { element: <ConversationPage />, path: 'conversation' },
    ],
  },
];
