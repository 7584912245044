// ----------------------------------------------------------------------

import { useAuthContext } from 'src/auth/hooks';
import CircularProgress from 'src/components/circular-progress';

type Props = {
  title?: string;
  image?: string;
  children: React.ReactNode;
};

export default function AuthClassicLayout({ children, image, title }: Props) {
  // const mdUp = useResponsive('up', 'md');
  const { loading } = useAuthContext();
  const renderContent = (
    <
      // sx={{
      //   width: 1,
      //   mx: 'auto',
      //   maxWidth: 480,
      //   px: { xs: 2, md: 8 },
      //   pt: { xs: 15, md: 20 },
      //   pb: { xs: 15, md: 0 },
      // }}
    >
      {children}
    </>
  );

  return (
    <
      // component="main"
      // direction="row"
      // sx={{
      //   minHeight: '100vh',
      // }}
    >
      {/* {renderLogo} */}

      {/* {mdUp && renderSection} */}

      {loading ? <CircularProgress /> : renderContent}
    </>
  );
}
