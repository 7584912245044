import React, { useState } from 'react';
import LoginOtp from './login-otp';
import LoginPhone from './login-phone';

function LoginContainer({ onSuccess }: { onSuccess?: () => void }) {
  const [hasStartedVerification, setHasStartedVerification] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('');
  return (
    <div className="rounded-2xl  h-full flex flex-col items-center justify-center">
      {hasStartedVerification ? (
        <LoginOtp phone={phoneNumber} countryCode={countryCode} />
      ) : (
        <LoginPhone
          onSuccess={onSuccess}
          onNextStep={({ mobile, code }: { mobile: string; code: string }) => {
            setPhoneNumber(mobile);
            setCountryCode(code);
            setHasStartedVerification(true);
          }}
        />
      )}
    </div>
  );
}

export default LoginContainer;
