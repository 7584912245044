import { UseMutationResult, useMutation, useQuery } from '@tanstack/react-query';
import axiosInstance from 'src/utils/axios';

const getCountryCodes = async (): Promise<TCountryCodeResponse> => {
  const { data } = await axiosInstance.get(`/api/country-code`);
  return data;
};
const postWish = async (body: TCreateWishBody): Promise<TGlobalUserResponse<string>> => {
  const { data } = await axiosInstance.post(`/api/new-wish`, body);
  return data;
};

function useGetCountryCode() {
  // const queryClient = useQueryClient();

  return useQuery({ queryKey: ['countryCodes'], queryFn: () => getCountryCodes() });
}

const usePostNewWish = (): UseMutationResult<
  TGlobalUserResponse<string>,
  unknown,
  TCreateWishBody
> => useMutation({ mutationFn: (body: TCreateWishBody) => postWish(body) });

export { useGetCountryCode, usePostNewWish };
