import { AxiosError } from 'axios';
import { useMemo, useEffect, useReducer, useCallback } from 'react';

import { useGetUserInfo } from 'src/api/auth';

import axiosInstance from 'src/utils/axios';
import { setSession } from './utils';
import { AuthContext } from './auth-context';
import { AuthUserType, ActionMapType, AuthStateType } from '../../types';
import { useSearchParams } from 'react-router-dom';
// import { instance } from 'src/api/instance';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };

  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }

  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      loading: false,
      user: null,
      authenticated: true,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [searchParams] = useSearchParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const defaultToken = searchParams.get('token');
  const accessToken = localStorage.getItem(STORAGE_KEY) || defaultToken;
  if (accessToken) {
    axiosInstance.defaults.headers.Authorization = `Bearer ${accessToken}`;
  }
  axiosInstance.interceptors.response.use(
    (response) =>
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      response,
    (error: AxiosError) =>
      new Promise((resolve, reject) => {
        if (
          error?.response?.status === 401 &&
          error?.response?.config?.url?.search('login') === -1
        ) {
          logout();
        } else {
          throw error;
        }
      })
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
  );
  axiosInstance.interceptors.response.use(
    (response) =>
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      response,
    (error: AxiosError) =>
      new Promise((resolve, reject) => {
        if (
          error?.response?.status === 401 &&
          error?.response?.config?.url?.search('login') === -1
        ) {
          logout();
        } else {
          throw error;
        }
      })
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
  );
  const { data: userInfoData } = useGetUserInfo({ enabled: !!accessToken });

  useEffect(() => {
    if (userInfoData) {
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: userInfoData.data,
        },
      });
    }
  }, [userInfoData]);

  useEffect(() => {
    if (!accessToken) {
      console.log('not', accessToken);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, [accessToken]);

  // LOGIN
  const login = useCallback(
    async (userData: TLoginVerifyResponse): Promise<void> => {
      localStorage.setItem(STORAGE_KEY, userData.token);
      dispatch({
        type: Types.LOGIN,
        payload: {
          user: userData.user,
        },
      });
    },
    [dispatch]
  );

  // LOGOUT
  const logout = useCallback(async () => {
    localStorage.removeItem(STORAGE_KEY);

    setSession(null);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;
  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      logout,
    }),
    [login, logout, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
