import { useState, useEffect } from 'react';
import CircularProgress from '../circular-progress';

// ----------------------------------------------------------------------

export default function SplashScreen() {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <div className=" h-full w-full flex items-center justify-center">
      <CircularProgress />
    </div>
  );
}
