import { useState, useEffect, useCallback } from 'react';

import { useAuthContext } from '../hooks';
import { SplashScreen } from 'src/components/loading-screen';
import { Dialog, DialogContent } from 'src/components/dialog';
import LoginContainer from 'src/sections/auth/login-container';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'src/routes/hooks';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const { loading } = useAuthContext();
  return loading ? <SplashScreen /> : <Container>{children}</Container>;
}

// ----------------------------------------------------------------------

function Container({ children }: Props) {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const queryClient = useQueryClient();
  const { user, loading } = useAuthContext();
  const router = useRouter();
  const check = useCallback(() => {
    if (!user && !loading) {
      // const searchParams = new URLSearchParams({
      //   returnTo: window.location.pathname,
      // }).toString();
      setShowLoginModal(true);

      // const href = `${paths.auth.login}?${searchParams}`;

      // router.replace(href);
    }
  }, [user, loading]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if (!checked) {
  //   return null;
  // }

  return (
    <>
      <Dialog open={showLoginModal}>
        <DialogContent>
          <LoginContainer
            onSuccess={() => {
              setShowLoginModal(false);
              router.reload();
            }}
          />
        </DialogContent>
      </Dialog>
      {children}
    </>
  );
}
